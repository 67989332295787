.banner {
    position: relative;
    overflow: hidden;
    padding-bottom: 5rem;
}

#banner-video-desktop,
#banner-video-mobile {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.bannercontainer {
    position: relative;
    z-index: 1;
}

.tagline, .taglinemob {
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-weight: 700;
    text-transform: uppercase;
}

.tagline span, .taglinemob span {
    color: #FF541C;
}

.description,
.description2 {
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-weight: 500;
}

.description span,
.description2 span {
    color: #FF7B02;
}
.lo-banner-name{
    color: #FF541C;
    font-family: Poppins;
    font-weight: 600;
}
.lo-banner-details{
    color: #FFF;
    font-family: Poppins;
    font-weight: 400;
}
.lo-banner-details a{
    color: #FFF;
    text-decoration: none;
}
.lo-banner-details a:hover{
    text-decoration: underline;
}
@media screen and (min-width: 992px) {
    .banner-deskvideo {
        display: block;
    }

    .banner-mobvideo {
        display: none;
    }
    .taglinemob{
        display: none;
    }
    .tagline {
        margin-top: 7rem;
    }

    .description,
    .description2 {
        margin-top: 2rem;
        font-size: 1.2rem;
    }
    .lo-banner-row{
        align-items: center;
        justify-content: center;
        margin-top: 2rem;
    }
    .kurt-huels-banner{
        float: right;
        background-image: url(../../LoJSONs/lo-bg-line.webp);
        background-repeat: no-repeat;
        background-size: contain;
        height: 220px;
        width: 180px;
        border-radius: 15px;
        padding: 10px;
    }
    .lo-banner-name{
        font-size: 2.25rem;
    }
    .lo-banner-details{
        font-size: 1.25rem;
    }
}

@media (max-width: 991px) {
    .banner-deskvideo {
        display: none;
    }

    .banner-mobvideo {
        display: block;
    }

    .banner {
        padding-bottom: 2rem;
    }
    .taglinemob{
        margin-top: 5rem;
        font-size: 22px;
    }
    .tagline {
        display: none;
    }
    .lo-banner-row{
        text-align: center;
        margin-top: 1rem;
    }
    .lo-banner-name{
        font-size: 2.5rem;
        margin-top: 1rem;
    }
}